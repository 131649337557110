<template>
  <ion-page>
    <ion-content v-if="user && user.profile">

      <ion-row class="ion-padding header-section" v-bind:class="{merchant:user.profile.role==='merchant'}">
        <ion-col size="12" style="text-align:right;">
          <Icon :name="'add-friend'" style="margin-right:3vw;" @click="addAFriend" v-if="user.profile.role==='consumer'" />
          <Icon :name="'gear'" @click="modalService.triggerEditProfile()" v-if="user.profile.role==='consumer'" />
          <Icon :name="'gear'" @click="modalService.triggerMerchantAccountSettings()" v-else-if="user.profile.role==='merchant'" />
        </ion-col>
      </ion-row>

      <ion-row v-if="user" class="profile-section">
        <ion-col size="12">
          <span class="profile-photo" v-bind:class="{merchant:user.profile.role==='merchant'}">
            <img v-if="user.profile.profile_photo" :src="user.profile.profile_photo">
          </span>
        </ion-col>
        <ion-col size="12" v-if="user.profile.role==='consumer'">
          <span class="red-title" style="padding-right:1vw">{{user.profile.first_name}}</span>
          <span class="ixkdhkjfn">{{user.profile.last_name}}</span>
        </ion-col>
        <ion-col size="12" v-else-if="user.profile.role==='merchant'">
          <span class="ixkdhkjfn" style="padding-right:1vw">{{user.profile.company_name}}</span>
          <span style="display:block;color:;#9F9F9F;font-size:12px;">{{user.profile.city}}</span>
        </ion-col>
        <ion-col size="12" v-if="user.profile.role==='consumer'">
          <span style="color:#9F9F9F;font-size:12px;">Joined {{moment(user.dates.created.toDate()).format('MMMM d, YYYY')}}</span>
        </ion-col>
        <ion-col size="12" v-if="user.profile.role==='consumer' && !tags.includes('profile-complete')" style="padding:5% 25%">
          <RoundButton :text="'Setup Profile'" :color="'#00777E'" :loading="false" @click="startProfileSetup" />
        </ion-col>
      </ion-row>

      <ion-row style="background:#fff;padding:2vh 0;text-align:center;" >
        <ion-col size="12" class="ion-align-self-center">
          <span class="ixkdhkjfn">This is</span>
          <span class="red-title" style="padding:0 1vw">your</span>
          <span class="ixkdhkjfn">impact</span>
        </ion-col>
        <ion-col size="12" class="ion-align-self-center">
          <Causes :items="null" :type="'profile'" :style="'margin:2vh 10vw;'"/>
        </ion-col>
      </ion-row>


      <ion-row :style="'height:100%;'">
        <ion-col size="12" style="padding:5vh 10vw;text-align:center;">
          <span style="font-family:'Satisfy', cursive !important;font-weight:400;font-size:25px;color:#ACACAC">This is where you'll find your activity on Halona</span>
          <RoundButton :text="'Find A Business'" :color="'#00777E'" :loading="false" @click="findABusiness" style="margin: 5% 20%;" />
        </ion-col>
      </ion-row>

    </ion-content>
  </ion-page>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router'

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonButton } from '@ionic/vue'
import { modalController  } from '@ionic/vue'; 

/* Components */
import Causes from '@/components/cards/Causes.vue'
import Icon from '@/components/widgets/Icon.vue'
import RoundButton from '@/components/buttons/Solid';
import OutlinedButton from '@/components/buttons/Outlined.vue'

/* Plugins */
import moment from 'moment'

/* Classes */
import { modalService } from "@/classes/modals"

export default  {
  name: 'profile',

  components: {
    IonContent, IonPage, IonRow, IonCol, IonButton,
    Causes, Icon, RoundButton, OutlinedButton
  },

  setup() {
    const { state } = useStore();
    const router = useRouter()
    const user = computed(() => state.auth.user);
    const tags = computed(() => state.auth.tags);
    const isLoading = computed(() => state.auth.loading);

    const addAFriend = async () => {
      const modal = await modalController
        .create({
          component: require('@/components/modals/Friends').default,
          cssClass: 'three-quarter-modal'
        })
      return modal.present();
    };

    const startProfileSetup = async () => {
      return modalService.triggerEditProfile()
    }

    const findABusiness = async () => {
      return router.push('/search')
    }

    return {
      user,
      tags,
      isLoading,

      addAFriend,
      startProfileSetup,
      findABusiness,

      modalService,
      moment
    };
  },

}
</script>
<style scoped>
ion-content {
  --background: #F1F1F1;
}

.header-section {
  height:153px;
  background:#CCD57E;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
}

.header-section.merchant {
  background:#004F7C;
}

.profile-section { margin-top: -65px; padding:0 0 5vh; }
.profile-section ion-col { text-align:center; }

.profile-photo {
  display:block;
  width:99px;
  height:99px;
  margin:0 auto;
  border:3px solid #CCD57E;
  background:#9F9F9F;
  border-radius:100%
}

.profile-photo.merchant {
  border: 2px solid #004F7C;
}

.profile-photo img { border-radius:100%;}


</style>



